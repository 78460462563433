import React from "react";
import "./Banner.css";
import Logo from "../../assets/icons/logo-transparente.svg";

const Banner = () => {
  return (
    <section className="banner">
      <article className="logo-banner">
        <h1 className="logo-banner__title">family market</h1>
        <h2 className="logo-banner__subtitle">valorizamos o seu tempo.</h2>

        <img
          className="logo-banner__image"
          src={Logo}
          alt="Imagem da logo da empresa, contendo o símbolo de uma família, um carrinho de supermercado e uma pipa."
        />
      </article>
    </section>
  );
};

export default Banner;
