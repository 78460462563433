import React from "react";
import "./Ul.css"

const Ul = ({ itens = [] }) => {
  return (
    <ul className="ul">
      {itens.map((item, index) => {
        return (
          <li className="ul__li" key={index}>
            {item}
          </li>
        );
      })}
    </ul>
  );
};

export default Ul;
