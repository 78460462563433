import React from "react";
import "./AboutUsPage.css";
import MainContent from "../../components/MainContent/MainContent";
import Container from "../../components/Container/Container";
import PurposeSection from "../../components/PurposeSection/PurposeSection";
import BenefitsSection from "../../components/BenefitsSection/BenefitsSection";
import DifferencesSection from "../../components/DifferencesSection/DifferencesSection";

const AboutUsPage = () => {
  return (
    <MainContent>
      <PurposeSection />

      <BenefitsSection />

      <DifferencesSection />
    </MainContent>
  );
};

export default AboutUsPage;
