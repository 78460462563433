import React from "react";
import "./Nav.css";
import { Link } from "react-router-dom";
import Logo from "../../assets/icons/logo-transparente.svg";

const Nav = ({ exibeNavBar, setExibeNavBar }) => {
  return (
    <nav className={`navbar ${exibeNavBar ? "exibeNavbar" : ""}`}>
      <ul className="links__box">
        <li>
          <Link
            className="navbar__item"
            to={`/`}
            onClick={() => setExibeNavBar(false)}
          >
            Início
          </Link>
        </li>

        <li>
          <Link
            className="navbar__item"
            to={`/aboutus`}
            onClick={() => setExibeNavBar(false)}
          >
            Quem somos
          </Link>
        </li>

        <li>
          <Link
            className="navbar__item"
            to="#contato"
            onClick={(event) => {
              event.preventDefault();
              const element = document.querySelector("#contato");
              element.scrollIntoView({ behavior: "smooth" });
              setExibeNavBar(false);
            }}
          >
            Contato
          </Link>
        </li>
      </ul>

      <div className="image__box">
        <img
          className="logo__nav"
          src={Logo}
          alt="Logo da empresa Family Market."
        />
      </div>
    </nav>
  );
};

export default Nav;
