import React from "react";
import "./Footer.css";
import Container from "../Container/Container";
import Email from "../../assets/icons/email.svg";
import Telefone from "../../assets/icons/whatsapp.svg";
import { Button } from "../FormComponents/FormComponents";
import { Link } from "react-router-dom";
import Logo from "../../assets/icons/logo-transparente.svg";

const Footer = () => {
  const business = {
    number: "5511911483461",
    email: "atendimento@familymarket.com.br",
    message: true,
    textMessage: "Olá!",
  };

  const getWhatsAppUrl = () => {
    const ua = navigator.userAgent || navigator.vendor || window.opera;
    let url;
    if (/android/i.test(ua)) {
      url = `whatsapp://send?phone=${business.number}${
        business.message ? `&text=${business.textMessage}` : ""
      }`;
    } else if (/iPad|iPhone|iPod/.test(ua) && !window.MSStream) {
      url = `whatsapp://send?phone=${business.number}${
        business.message ? `&text=${business.textMessage}` : ""
      }`;
    } else {
      url = `https://web.whatsapp.com/send?phone=${business.number}${
        business.message ? `&text=${business.textMessage}` : ""
      }`;
    }
    return url;
  };

  return (
    <footer id="contato" className="footer-page">
      <section className="footer-top__section">
        <Container>
          <h1 className="footer-top__title">fale conosco</h1>
          <div className="footer-top__content">
            <article className="footer-top__card">
              <h2 className="card__title">
                {" "}
                <i>
                  <img src={Email} alt="Ícone de email." />
                </i>{" "}
                E-MAIL
              </h2>
              <p>Tire as suas duvidas por e-mail:</p>

              <button
                className="card__link"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(`mailto:${business.email}`, "_blank");
                }}
              >
                enviar e-mail
              </button>
            </article>

            <article className="footer-top__card">
              <h2 className="card__title">
                <i>
                  <img src={Telefone} alt="Ícone de telefone." />
                </i>{" "}
                WhatsApp
              </h2>
              <p>Tire as suas duvidas por mensagem:</p>

              <button
                className="card__link"
                onClick={() => window.open(getWhatsAppUrl(), "_blank")}
              >
                enviar mensagem
              </button>
            </article>
          </div>
        </Container>
      </section>

      <section className="footer-bottom__section">
        <Container>
          <div className="footer-bottom__content">
            <img src={Logo} alt="" />
          </div>
        </Container>
      </section>
    </footer>
  );
};

export default Footer;
