// import React from "react";

import styled from "styled-components";
import { Theme } from "../../theme";

// const Title = ({ titleText = "", addtionalClass = "" }) => {
//   return <h1 className={`title ${addtionalClass}`}>{titleText}</h1>;
// };

// export default Title;

export const Title = styled.h1`
  font-size: 30px;
  font-weight: 700;
  font-family: Poppins-Regular;
  color: ${Theme.colors.whiteColor};
`;
