import React from "react";
import "./DifferencesSection.css";
import Container from "../Container/Container";
import HomemComCarrinho from "../../assets/icons/HomemComCarrinho.svg";
import Ul from "../Ul/Ul";

const DifferencesSection = () => {
  const diferencas = [
    "Empresa independente.",
    "Atendimento personalizado.",
    "Proteção às nossas crianças.",
    "Experiência simples e rápida.",
    "Flexibilidade nas negociações.",
    "Respeitamos a privacidade dos clientes.",
    "Oportunidade para condôminos empreendedores.",
  ];

  return (
    <section className="differences__section">
      <Container>
        <div className="differences__content">
          <h1 className="differences__content-title">Diferenciais</h1>

          <div className="differences__box">
            <img
              src={HomemComCarrinho}
              alt="Imagem de homem observando papel. Ele está apoiado em um carrinho de supermercado."
            />
            <div className="differences__lista">
              <h1 className="differences__content-title">Diferenciais</h1>
              <Ul itens={diferencas} />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default DifferencesSection;
