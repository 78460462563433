import React from "react";
import "./BenefitsSection.css";
import Container from "../Container/Container";
import Ul from "../Ul/Ul";

const BenefitsSection = () => {
  const beneficios = [
    "Funcionamento 24/7.",
    "Valorização do imóvel.",
    "Não cobramos pela instalação.",
    "Renda extra para o condomínio.",
    "Reposição planejada e otimizada.",
    "Trabalhamos com preços competitivos.",
    "Trabalhamos com as melhores marcas do mercado.",
  ];

  return (
    <section className="benefits__section">
      <Container>
        <div className="benefits__content">
          <h1 className="benefits__content-title">Benefícios</h1>

          <Ul itens={beneficios} />
        </div>
      </Container>
    </section>
  );
};

export default BenefitsSection;
