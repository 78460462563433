import React from "react";
import "./PurposeSection.css";
import Container from "../Container/Container";
import MulherComCarrinho from "../../assets/icons/MulherComCarrinho.svg";

const PurposeSection = () => {
  return (
    <section className="who__section">
      <Container>
        <div className="who__content">
          <h1 className="who__content--title">quem somos ?</h1>

          <div className="who__content-box">
            <div className="who__text">
              <h2 className="who__text--subtitle">Propósito</h2>

              <p className="who__text--paragraph">
                Proporcionar praticidade na vida das pessoas, com experiências
                de compra simples e rápidas, para que todos possam aproveitar ao
                máximo o que realmente importa em suas vidas.
              </p>
            </div>

            <img
              src={MulherComCarrinho}
              alt="Imagem de mulher com carrinho de compras."
            />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default PurposeSection;
