import React from "react";
import "./HomePage.css";
import Banner from "../../components/Banner/Banner";
import Container from "../../components/Container/Container";
import Prateleira from "../../assets/images/color-images/prateleira-colorida.jpg";
import PrateleiraFrutas from "../../assets/images/color-images/PrateleiraFrutas-colorida.jpg";

import PrateleiraSucrilhos from "../../assets/images/color-images/PrateleiraSucrilhos.jpg";

import CorredorComGeladeira from "../../assets/images/color-images/CorredorComGeladeira.jpg";
import DanonesSupermercado from "../../assets/images/color-images/DanonesSupermercado-colorida.jpg";
import CorredorCurvoColorido from "../../assets/images/color-images/CorredorCurvoColorido.jpg";

import MainContent from "../../components/MainContent/MainContent";
import BenefitsSection from "../../components/BenefitsSection/BenefitsSection";
import DifferencesSection from "../../components/DifferencesSection/DifferencesSection";

const HomePage = () => {
  return (
    <MainContent>
      <Banner />

      <section id="about-us__section" className="about-us__section">
        <Container>
          <div className="home__content about-us__content">
            <h1 className="home--title about-us__section--title">sobre nós</h1>

            <p className="home--paragrafo">
              Bem-vindo(a) à Family Market, nascemos para oferecer uma solução
              prática para o dia a dia, com uma experiência de compra rápida e
              fácil, para que todos possam aproveitar o que realmente importa.
            </p>

            <img
              src={Prateleira}
              alt="Imagem de prateleira com itens de supermercado."
            />
          </div>
        </Container>
      </section>

      <section className="modelos-lojas__section">
        <Container>
          <div className="home__content modelos-lojas__content">
            <h1 className="home--title modelos-lojas__section--title">
              modelos de lojas
            </h1>

            <p className="home--paragrafo">
              Nossas lojas são idealizadas e projetadas para atender à
              especificidade do seu condomínio, seja em um local existente, seja
              em um novo local.
            </p>

            <div className="modelos-lojas__images-box">
              <img
                src={CorredorCurvoColorido}
                alt="Imagem de corredor de um supermercado."
              />

              <img
                src={DanonesSupermercado}
                alt="Imagem de prateleiras de um supermercado."
              />

              <img
                src={CorredorComGeladeira}
                alt="Imagem de um corredor de supermercado contendo uma geladeira."
              />
            </div>
          </div>
        </Container>
      </section>

      <BenefitsSection />

      <DifferencesSection />
    </MainContent>
  );
};

export default HomePage;
