import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

@font-face {
  font-family: Inter-Regular;
  src: url("./assets/fonts/Inter/Inter-Regular.ttf") format("truetype");

  font-family: Inter-Medium;
  src: url("./assets/fonts/Inter/Inter-Medium.ttf") format("truetype");

  font-family: Poppins-Regular;
  src: url(./assets/fonts/Poppins/Poppins-Black.ttf);

  font-family: Poppins-SemiBold;
  src: url(./assets/fonts/Poppins/Poppins-SemiBold.ttf);

  font-family: Poly-Regular;
  src: url(./assets/fonts/Poly/Poly-Regular.ttf);
}

*{
    margin: 0;
    padding:0;
    box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Inter-Regular", fallback, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
`;
