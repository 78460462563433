import React, { useState } from "react";
import "./Header.css";
import Logo from "../../assets/icons/logo-transparente.svg";
import Nav from "../Nav/Nav";
import { Link } from "react-router-dom";
import { Title } from "../Title/Title";
import { SubTitle } from "../SubTitle/SubTitle";

const Header = () => {
  const [exibeNavBar, setExibeNavBar] = useState(false);
  return (
    <header className="header">
      <button
        onClick={() =>
          exibeNavBar ? setExibeNavBar(false) : setExibeNavBar(true)
        }
        className={`hamburguer ${exibeNavBar ? "fechar" : ""}`}
      ></button>

      <Nav exibeNavBar={exibeNavBar} setExibeNavBar={setExibeNavBar} />

      <div className="logo-link__flex">
        <Link className="logo-link__content" to={"/"}>
          <img
            onClick={() => setExibeNavBar(false)}
            className="logo"
            src={Logo}
            alt="Logo da empresa Family Market."
          />
        </Link>
      </div>
    </header>
  );
};

export default Header;
