export const Theme = {
  colors: {
    whiteColor: "#ffffff",
    blackColor: "#000000",
    whiteColorGradient: "#555555",
    blackColorGradient: "#010101",
  },

  sizes: {},
};
